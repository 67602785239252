import { render, staticRenderFns } from "./PastPrescriptions.vue?vue&type=template&id=32b27bf9&scoped=true&"
import script from "./PastPrescriptions.vue?vue&type=script&lang=ts&"
export * from "./PastPrescriptions.vue?vue&type=script&lang=ts&"
import style0 from "./PastPrescriptions.vue?vue&type=style&index=0&id=32b27bf9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b27bf9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BBadge } from 'bootstrap-vue'
import { BCollapse } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
installComponents(component, {BBadge,BCollapse,BListGroup,BListGroupItem})
