















































import { Component, Vue, Prop } from 'vue-property-decorator'
import Dose from '@/models/PrescriptionDose'
import SigLine from '@/models/PrescriptionSigLine'
import DoseTime, { ALL_DOSE_TIMES } from '@/util/DoseTime'

@Component
export default class DoseTimeSelect extends Vue {
  @Prop({ type: Object, required: true })
  sigLine!: SigLine

  @Prop(String)
  formLabel?: string

  @Prop(String)
  formLabelPlural?: string

  @Prop(Boolean)
  showDescription!: boolean

  @Prop(Boolean)
  isValid!: boolean

  initialDoseTimes: string[] = []
  allDoseTimes = ALL_DOSE_TIMES

  get doses(): Dose[] {
    return this.sigLine.doses || []
  }

  get allDosesValid(): boolean {
    return this.doses.every((dose, i) => this.doseIsValid(i))
  }

  doseHasChanged(doseIndex: number): boolean {
    const dose = this.doses[doseIndex]
    const initialTime = this.initialDoseTimes[doseIndex]

    if (!dose || !initialTime) return false
    return dose.time !== initialTime
  }

  resetDose(doseIndex: number): void {
    const dose = this.doses[doseIndex]
    const initialTime = this.initialDoseTimes[doseIndex]

    if (!dose || !initialTime) return
    dose.time = initialTime
  }

  hoaQuantityText(quantity: number): string {
    const { formLabel, formLabelPlural } = this
    const units = quantity > 1 ? formLabelPlural : formLabel
    return units ? `x ${quantity} ${units}` : `x ${quantity}`
  }

  getOtherTimes(doseIndex: number): DoseTime[] {
    if (!this.sigLine.doses) return []
    return this.sigLine.doses.filter((_, i) => i !== doseIndex).map(dose => new DoseTime(dose.time))
  }

  getDoseTimeOptions(doseIndex: number) {
    const otherTimes = this.getOtherTimes(doseIndex)
    return DoseTime.getValidDoseTimes(otherTimes)
  }

  doseInputId(doseIndex: number): string {
    return `sig-${this.sigLine.id}-dose-${doseIndex}`
  }

  doseIsValid(doseIndex: number): boolean {
    // loop through the sig lines
    // return false if another time is within 4 hours
    const otherTimes = this.getOtherTimes(doseIndex)
    const dose = this.doses[doseIndex]
    const doseTime = new DoseTime(dose.time)
    return doseTime.getIsValidAgainstMultiple(otherTimes)
  }

  created() {
    this.initialDoseTimes = this.doses.map(d => d.time).slice()

    this.$watch('allDosesValid', isValid => {
      this.$emit('update:isValid', isValid)
    })
  }
}
