























import { Component, Vue, Prop } from 'vue-property-decorator'

import DashboardMedication, { MedicationType } from '@/models/DashboardMedication'
import PageHeader from '@/components/PageHeader.vue'
import MedStatusLine from '@/components/medications/MedStatusLine.vue'
import ShipmentStatusLine from '@/components/medications/ShipmentStatusLine.vue'
import MedImage from '@/components/medications/MedImage.vue'
import PrescriptionInfo from '@/components/medications/PrescriptionInfo.vue'

@Component({
  components: {
    PageHeader,
    MedStatusLine,
    ShipmentStatusLine,
    MedImage,
    PrescriptionInfo,
  },
})
export default class MedicationDetails extends Vue {
  @Prop({ type: DashboardMedication, required: true })
  medication!: DashboardMedication

  get isUserMedication(): boolean {
    return this.medication.type === MedicationType.UserMedication
  }

  get breadcrumbs() {
    return [
      {
        text: this.$t('Medications'),
        to: { name: 'medications' },
      },
      {
        text: this.medication.description,
        to: { name: 'medications' },
      },
    ]
  }

  created() {
    // Set the page title to the name of the drug when this component loads
    if (this.medication.description) {
      this.$store.commit('setPageTitle', this.medication.description)
    }
  }
}
