import { render, staticRenderFns } from "./MedShipmentDetails.vue?vue&type=template&id=25ab8779&scoped=true&"
import script from "./MedShipmentDetails.vue?vue&type=script&lang=ts&"
export * from "./MedShipmentDetails.vue?vue&type=script&lang=ts&"
import style0 from "./MedShipmentDetails.vue?vue&type=style&index=0&id=25ab8779&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ab8779",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BCardHeader } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
import { BLink } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import IconDaysSupply30 from '@/assets/icons/icon-days-supply-30.svg'
import IconDaysSupply90 from '@/assets/icons/icon-days-supply-90.svg'
import IconDaysSupplyQuantity from '@/assets/icons/icon-days-supply-quantity.svg'
import IconQuestionMark from '@/assets/icons/icon-question-mark.svg'
installComponents(component, {BButton,BCardHeader,BCardTitle,BLink,BListGroup,IconDaysSupply30,IconDaysSupply90,IconDaysSupplyQuantity,IconQuestionMark})
