import { render, staticRenderFns } from "./ArrivalStatus.vue?vue&type=template&id=5b1e0c1a&"
import script from "./ArrivalStatus.vue?vue&type=script&lang=ts&"
export * from "./ArrivalStatus.vue?vue&type=script&lang=ts&"
import style0 from "./ArrivalStatus.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
import IconLivechatSmall from '@/assets/icons/icon-livechat-small.svg'
import IconPhoneSmallOutline from '@/assets/icons/icon-phone-small-outline.svg'
installComponents(component, {BListGroupItem,BMedia,IconLivechatSmall,IconPhoneSmallOutline})
