import { render, staticRenderFns } from "./MedPreferencesInfoModal.vue?vue&type=template&id=4f5dec8c&"
import script from "./MedPreferencesInfoModal.vue?vue&type=script&lang=ts&"
export * from "./MedPreferencesInfoModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BLink } from 'bootstrap-vue'
import IconLivechatSmall from '@/assets/icons/icon-livechat-small.svg'
import IconPhoneSmallOutline from '@/assets/icons/icon-phone-small-outline.svg'
installComponents(component, {BButton,BLink,IconLivechatSmall,IconPhoneSmallOutline})
