


















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import DashboardMedication, { MedicationType } from '@/models/DashboardMedication'
import { formatDate } from '@/i18n'
import { ChaseStates } from '@/models/PrescriptionRequest'
import UnreachableCaseError from '@/util/UnreachableCaseError'
import { MedicationStatus, medStatusFinder } from '@/util/medStatusHelpers'

import Card from '@/components/Card.vue'
import PrescriberInfo from '@/components/medications/PrescriberInfo.vue'
import AutoRefillModal from '@/components/medications/AutoRefillModal.vue'
import MedDetailsListItem from '@/components/medications/MedDetailsListItem.vue'

interface StatusCopy {
  header: string | TranslateResult
  description?: string | TranslateResult
}

@Component({
  components: {
    Card,
    PrescriberInfo,
    AutoRefillModal,
    MedDetailsListItem,
  },
})
export default class MedStatusCard extends Vue {
  @Prop({ type: DashboardMedication, required: true })
  med!: DashboardMedication

  @Prop({ type: Boolean, default: false })
  past!: boolean

  get primaryRx() {
    return this.med.primaryPrescription
  }

  get showStatusCard() {
    return !['active', 'unknown', 'received'].includes(this.medStatus)
  }

  get medStatus(): MedicationStatus {
    return medStatusFinder(this.med)
  }

  get statusCopy(): StatusCopy {
    const status: MedicationStatus = this.medStatus

    let header = this.$t('medStatus.unknown.header')
    let description = this.$t('medStatus.unknown.description')
    let deadline: string | undefined

    const fullName = this.med.physician ? this.med.physician.fullName : ''
    const count = this.med.remainingRefills
    const chase = this.med.prescriptionRequest

    switch (status) {
      case 'discontinued':
      case 'expired':
        if (this.med.selfPrescribed) {
          header = this.$t('medStatus.discontinued.otc.header')
          description = this.$t('medStatus.discontinued.otc.octDescription')
        } else {
          header = this.$t('medStatus.discontinued.header')
          description = this.$t('medStatus.discontinued.description')
        }
        break
      case 'pending_otc':
        header = this.$t('chase.status.processing.header')
        description = this.$t('chase.status.processing.description')
        break
      case 'pending':
        header = this.$t('chase.status.preChase.header')
        description = this.$t('chase.status.preChase.description', { fullName })
        break
      case 'new_chase':
        header = this.$t('chase.status.newChase.header')
        description = this.$t('chase.status.newChase.description', { fullName })
        break
      case 'in_progress':
        deadline = chase && formatDate(chase.dueDate, 'MMMM D')
        header = this.$t('chase.status.inProgress.header', {
          deadline,
        })
        description = this.$t('chase.status.inProgress.description')
        break
      case 'has_error':
        header = this.$t('chase.status.error.header')
        description = this.$t('chase.status.error.description')
        break
      case 'unable_to_acquire':
        header = this.$t('chase.status.unable.header')
        description = this.$t('chase.status.unable.description')
        break
      case 'denied':
        header = this.$t('chase.status.denied.header')
        description = this.$t('chase.status.denied.description', { fullName })
        break
      case 'received_signup_med':
        description = this.$t('chase.status.renewed.firstShipment.description', {
          fullName,
          count,
        })
        header = this.$t('chase.status.renewed.firstShipment.header')
        break
      case 'pre_rph_check':
        header = this.$t('medStatus.preRphCheck.header')
        description = this.$t('medStatus.preRphCheck.description')
        break
      case 'ortf':
        header = this.$t('medStatus.ortf.header')
        description = this.$t('medStatus.ortf.description')
        break
      case 'received':
      case 'active': // should not even display this component if it is active
      case 'unknown':
        break
      default:
        throw new UnreachableCaseError(status)
    }

    return { header, description }
  }

  get showPrescriberCard(): boolean {
    return (
      !!this.med.physician &&
      (this.hasActiveChase || this.med.type === MedicationType.SignupMedication)
    )
  }

  get hasActiveChase(): boolean {
    const rxReq = this.med.prescriptionRequest
    const activeStates = [
      ChaseStates.Denied,
      ChaseStates.InProgress,
      ChaseStates.NewChase,
      ChaseStates.Pending,
      ChaseStates.UnableToAcquire,
    ]

    return !!rxReq && activeStates.includes(rxReq.state)
  }

  get showMedicationPreferences() {
    return this.med.formPreference || this.med.daysSupplyPreference
  }
}
