

























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import Prescription from '@/models/Prescription'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import PrescriptionDetails from '@/components/medications/PrescriptionDetails.vue'
import { formatDate } from '@/i18n'

@Component({
  components: {
    LinkArrowIcon,

    PrescriptionDetails,
  },
})
export default class PastPrescriptions extends Vue {
  @Prop({ type: Array, required: true })
  prescriptions!: Prescription[]

  endedText(rx: Prescription): string | TranslateResult {
    return this.$t('Ended {date}', { date: formatDate(rx.endDate!, 'M/D/YYYY') })
  }
}
