






















import { Component, Vue, Prop } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import DashboardMedication from '@/models/DashboardMedication'

@Component({
  components: {
    Modal,
  },
})
export default class ConfirmDeleteMedicationModal extends Vue {
  @Prop({ type: Object, required: true })
  med!: DashboardMedication

  isSubmitting = false

  async onSubmit(ok: () => void) {
    this.isSubmitting = true

    await this.$store.dispatch('medications/delete', { id: this.med.id })

    this.isSubmitting = false

    ok()
  }
}
