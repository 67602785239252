
























import { Component, Vue, Prop } from 'vue-property-decorator'
import Physician from '@/models/Physician'

@Component
export default class PrescriberInfo extends Vue {
  @Prop(Object)
  prescriber?: Physician
}
