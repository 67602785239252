










































import { Component, Vue, Prop } from 'vue-property-decorator'

import { isFuture, isPast } from 'date-fns'
import { TranslateResult } from 'vue-i18n'
import DashboardMedication from '@/models/DashboardMedication'
import Prescription from '@/models/Prescription'
import MedInfoBase from '@/components/medications/MedInfoBase.vue'
import PrescriptionDetails from '@/components/medications/PrescriptionDetails.vue'
import PastPrescriptions from '@/components/medications/PastPrescriptions.vue'
import { formatDate } from '@/i18n'
import Card from '@/components/Card.vue'

@Component({
  components: {
    Card,
    MedInfoBase,
    PrescriptionDetails,
    PastPrescriptions,
  },
})
export default class PrescriptionInfo extends Vue {
  @Prop({ type: DashboardMedication, required: true })
  medication!: DashboardMedication

  endOrStartText(rx: Prescription): string | TranslateResult {
    if (rx.startDate && isFuture(rx.startDate)) {
      return this.$t('Starts {date}', { date: formatDate(rx.startDate, 'M/D/YYYY') })
    } else if (rx.endDate && isPast(rx.endDate)) {
      return this.$t('Ended {date}', { date: formatDate(rx.endDate, 'M/D/YYYY') })
    } else if (rx.endDate) {
      return this.$t('Ends {date}', { date: formatDate(rx.endDate, 'M/D/YYYY') })
    } else {
      return ''
    }
  }

  shouldHideMedicationPreferences(rx: Prescription) {
    if (rx.selfPrescribed) return false // B2C-1654 Fill preference editing disbaled for OTCs
    return this.medication.primaryPrescriptionId === rx.id
  }
}
