














import { mapGetters } from 'vuex'
import { Component, Vue, Prop } from 'vue-property-decorator'

import DashboardMedication from '@/models/DashboardMedication'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import MedicationDetails from '@/components/medications/MedicationDetails.vue'
import NotFound from '@/components/NotFound.vue'
import { PPError } from '@/ppapi/PPError'

@Component({
  components: { LoadingSpinner, LinkArrowIcon, MedicationDetails, NotFound },
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
    ...mapGetters('medications', ['getMedication', 'getLiterature']),
  },
})
export default class MedicationDetailsView extends Vue {
  getError!: (key: string, id?: string) => PPError | null
  isInProgress!: (key: string, id?: string) => boolean
  getMedication!: (id: string) => DashboardMedication | null

  @Prop({ type: String, required: true })
  type!: string

  @Prop({ type: String, required: true })
  id!: string

  get medication(): DashboardMedication | null {
    return this.getMedication(this.id)
  }

  get isLoading(): boolean {
    return this.isInProgress('medications/loadOne', this.id)
  }

  loadMed() {
    return this.$store.dispatch('medications/loadOne', {
      id: this.id,
      type: this.type,
    })
  }

  async ensureLiterature() {
    if (!this.medication) return Promise.resolve(null)
    return this.$store.dispatch('medications/ensureLiterature', this.medication)
  }

  async created(): Promise<void> {
    if (!this.medication) {
      await this.loadMed()
    }

    this.ensureLiterature()
  }
}
