import { render, staticRenderFns } from "./PrescriberInfo.vue?vue&type=template&id=822dcdbc&scoped=true&"
import script from "./PrescriberInfo.vue?vue&type=script&lang=ts&"
export * from "./PrescriberInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "822dcdbc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BMedia } from 'bootstrap-vue'
import IconPhoneSmall from '@/assets/icons/icon-phone-small.svg'
import IconPlaceMarker from '@/assets/icons/icon-place-marker.svg'
import IconPrescriber from '@/assets/icons/icon-prescriber.svg'
installComponents(component, {BMedia,IconPhoneSmall,IconPlaceMarker,IconPrescriber})
