


















import { Component, Vue, Prop } from 'vue-property-decorator'
import DashboardMedication from '@/models/DashboardMedication'

import Card from '@/components/Card.vue'

@Component({
  components: {
    Card,
  },
})
export default class MedDetailsActionCard extends Vue {
  @Prop({ type: Object, required: true })
  med!: DashboardMedication

  get isVisible() {
    return this.med.canDelete
  }
}
