
















import { Component, Vue, Prop } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import DashboardMedication from '@/models/DashboardMedication'

@Component({
  components: {
    Modal,
  },
})
export default class CancelRefillModal extends Vue {
  @Prop({ type: Object, required: true })
  med!: DashboardMedication

  isSubmitting = false

  get confirmationMessage() {
    if (this.med.canCancelRefill) {
      return this.$t('Are you sure you want to cancel this refill?')
    } else if (this.med.canCancelRush) {
      return this.$t('Are you sure you want to cancel this rush shipment?')
    }
    return ''
  }

  async onSubmit(ok: () => void) {
    this.isSubmitting = true

    if (this.med.canCancelRefill) {
      await this.$store.dispatch('medications/cancelRefillRequest', { id: this.med.id })
    } else if (this.med.canCancelRush) {
      await this.$store.dispatch('medications/cancelRushShipment', { id: this.med.id })
    }

    this.isSubmitting = false

    ok()
  }
}
