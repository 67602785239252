import { render, staticRenderFns } from "./MedLiterature.vue?vue&type=template&id=15551ee0&scoped=true&"
import script from "./MedLiterature.vue?vue&type=script&lang=ts&"
export * from "./MedLiterature.vue?vue&type=script&lang=ts&"
import style0 from "./MedLiterature.vue?vue&type=style&index=0&id=15551ee0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15551ee0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCardBody } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
import { BCollapse } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import IconPrint from '@/assets/icons/icon-print.svg'
installComponents(component, {BCardBody,BCardTitle,BCollapse,BListGroup,BListGroupItem,IconPrint})
