































































import { Component, Vue, Prop } from 'vue-property-decorator'
import MedicationLiterature from '@/models/MedicationLiterature'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import Card from '@/components/Card.vue'
import DashboardMedication from '@/models/DashboardMedication'
import getApiHost from '@/util/apiHost'

@Component({
  components: {
    Card,
    LinkArrowIcon,
  },
})
export default class MedLiterature extends Vue {
  @Prop({ type: MedicationLiterature })
  literature!: MedicationLiterature

  @Prop({ type: DashboardMedication, required: true })
  medication!: DashboardMedication

  @Prop({ type: Boolean })
  loading!: boolean

  @Prop({ type: Boolean })
  hasError!: boolean

  get medSummary() {
    if (!this.literature.sections) return ''
    return this.literature.sections[0].answer || ''
  }

  get otherSections() {
    if (!this.literature.sections) return []
    return this.literature.sections.slice(1)
  }

  get pdfUrl() {
    const apiHost: String = getApiHost()
    return `${apiHost}/medication/literature/${this.medication.ndc9}.pdf`
  }
}
