






























import { Component, Vue, Prop } from 'vue-property-decorator'
import DashboardMedication, { Group, AutoRefillActions } from '@/models/DashboardMedication'
import Icon from '@/components/Icon.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import UnreachableCaseError from '@/util/UnreachableCaseError'

@Component({
  components: { Icon, LinkArrowIcon },
})
export default class AutoshipStatus extends Vue {
  @Prop({ type: DashboardMedication, required: true })
  medication!: DashboardMedication

  get buttonTestId() {
    let id: string

    switch (this.medication.autoRefillAction) {
      case AutoRefillActions.Start:
      case AutoRefillActions.Resume:
        id = 'resume-med'
        break
      case AutoRefillActions.Pause:
        id = 'pause-med'
        break
      case AutoRefillActions.None:
        id = 'disabled-button'
        break
      default:
        throw new UnreachableCaseError(this.medication.autoRefillAction)
    }
    return id
  }

  get modalId() {
    let id: string | undefined

    switch (this.medication.autoRefillAction) {
      case AutoRefillActions.Start:
      case AutoRefillActions.Resume:
      case AutoRefillActions.Pause:
        id = 'auto-refill'
        break
      case AutoRefillActions.None:
        id = undefined
        break
      default:
        throw new UnreachableCaseError(this.medication.autoRefillAction)
    }

    return id
  }

  get showAutofill(): boolean {
    const rx = this.medication.primaryPrescription
    return !!rx && this.medication.group === Group.Active
  }
}
