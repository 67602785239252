



























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import { TranslateResult } from 'vue-i18n'
import Modal from '@/components/Modal.vue'
import Icon from '@/components/Icon.vue'
import RadioButtons from '@/components/inputs/RadioButtons.vue'
import DoseTimeSelect from '@/components/medications/DoseTimeSelect.vue'
import Prescription from '@/models/Prescription'
import DashboardMedication from '@/models/DashboardMedication'
import { FillPreference, FillPreferenceString } from '@/models/MedChangeRequest'
import { BannerInfo } from '@/vuex/banners'
import DoseTime from '@/util/DoseTime'
import SigLine from '@/models/PrescriptionSigLine'

interface FillPreferenceOption {
  value: FillPreferenceString
  text: string | TranslateResult
  icon: string
  disabled?: boolean
}

@Component({
  components: {
    Modal,
    Icon,
    RadioButtons,
    DoseTimeSelect,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'hasSucceeded', 'getError']),
  },
})
export default class EditPreferencesModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  isInProgress!: (key: string) => boolean
  getError!: (key: string) => Error | undefined

  @Prop({ type: String, required: true })
  modalId!: string

  @Prop({ type: Object, required: true })
  medication!: DashboardMedication

  @Prop({ type: Object, required: true })
  prescription!: Prescription

  fillPreferenceString: FillPreferenceString = 'packet'
  hoaPreferences: any[] = []
  doseTimesValid: boolean = true

  get packetsSelected() {
    return this.fillPreferenceString === 'packet'
  }

  getDoseTimeOptions(sig: SigLine, doseIndex: number) {
    if (!sig.doses) return []

    const otherTimes = sig.doses
      .filter((_, i) => i !== doseIndex)
      .map(dose => new DoseTime(dose.time))

    return DoseTime.getValidDoseTimes(otherTimes)
  }

  get options(): FillPreferenceOption[] {
    return [
      {
        value: 'packet',
        text: this.$t('In Packets'),
        icon: 'packet',
        disabled: !this.prescription.packetable,
      },
      {
        value: 'bulk',
        text: this.$t('In Bottle'),
        icon: 'bottle',
      },
    ]
  }

  get isInvalid() {
    const hoaUnchanged =
      this.packetsSelected && isEqual(this.hoaPreferences, this.prescription.sigLines || [])
    const inPacketsUnchanged = this.fillPreference.inPackets === this.prescription.inPackets
    const doseTimesInvalid = this.packetsSelected && !this.doseTimesValid
    return (hoaUnchanged && inPacketsUnchanged) || doseTimesInvalid
  }

  get inProgress() {
    return this.isInProgress('medications/medPreferenceChange')
  }

  get fillPreference(): FillPreference {
    const daysSupply = 30 // We currently don't allow changing the daysSupply
    const inPackets = this.fillPreferenceString.startsWith('packet')
    return { daysSupply, inPackets }
  }

  onShow() {
    this.hoaPreferences = cloneDeep(this.prescription.sigLines || [])
  }

  async onSubmit(ok: () => void) {
    if (this.isInvalid) return

    const hoaPreferences = this.packetsSelected
      ? this.hoaPreferences.filter((pref, i) => {
          return !isEqual(pref, this.prescription?.sigLines?.[i])
        })
      : []

    await this.$store.dispatch('medications/medPreferenceChange', {
      medication: this.medication,
      prescription: this.prescription,
      ...this.fillPreference,
      hoaPreferences,
      outreachConsent: true, // User agrees by clicking button
    })

    if (this.hasSucceeded('medications/medPreferenceChange')) {
      const banner: BannerInfo = {
        id: 'med-change-request-success',
        title: this.$t('Request sent!'),
        message: this.$t('Our team will review your request'),
        bgVariant: 'success',
        durationMs: 3000,
      }

      this.$store.dispatch('showBanner', banner)
      ok()
    }
  }
}
