import { render, staticRenderFns } from "./PrescriptionInfo.vue?vue&type=template&id=e0223cc6&scoped=true&"
import script from "./PrescriptionInfo.vue?vue&type=script&lang=ts&"
export * from "./PrescriptionInfo.vue?vue&type=script&lang=ts&"
import style0 from "./PrescriptionInfo.vue?vue&type=style&index=0&id=e0223cc6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0223cc6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCardHeader } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
import { BTab } from 'bootstrap-vue'
import { BTabs } from 'bootstrap-vue'
installComponents(component, {BCardHeader,BCardTitle,BTab,BTabs})
