



















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Prescription from '@/models/Prescription'
import DashboardMedication from '@/models/DashboardMedication'
import EditPreferencesModal from '@/components/medications/EditPreferencesModal.vue'
import Icon from '@/components/Icon.vue'
import PacketTimes from '@/components/medications/PacketTimes.vue'
import User from '@/models/User'

@Component({
  computed: {
    ...mapGetters('user', ['currentUser']),
  },
  components: {
    Icon,
    EditPreferencesModal,
    PacketTimes,
  },
})
export default class MedPreferences extends Vue {
  @Prop({ type: Object, required: true })
  medication!: DashboardMedication

  @Prop({ type: Object, required: true })
  prescription!: Prescription

  currentUser?: User

  openModal() {
    if (!this.canEdit) return
    this.$bvModal.show('edit-med-preferences')
  }

  get inPackets() {
    return this.prescription.inPackets
  }

  get packageText() {
    if (!this.prescription) return ''

    switch (this.prescription.medicationType) {
      case 'packet':
        return this.$t('In Packets')
      case 'bottle':
        return this.$t('In Bottle')
      default:
        return this.prescription.medicationType
    }
  }

  get pendingChanges() {
    if (!this.prescription.hasOpenChange) return []
    return this.prescription.medChangeRequests
      .map(request => request.composeChanges(this.prescription))
      .flat()
  }

  get hasPendingChanges() {
    return this.pendingChanges.length > 0
  }

  get canEdit() {
    // A medication cannot be changed if it
    // * is a 90 day supply
    // * has a pending change
    // * is as-needed
    // * is not packetable (this might change, but since the only preference is bulk/packets, this case would leave the user without a choice)
    return (
      !this.hasPendingChanges &&
      this.prescription.scheduled &&
      this.prescription.dispenseDaysSupply !== 90 &&
      this.prescription.packetable
    )
  }
}
