





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import MedicationLiterature from '@/models/MedicationLiterature'
import DashboardMedication from '@/models/DashboardMedication'
import MedDetailsListItem from '@/components/medications/MedDetailsListItem.vue'
import Card from '@/components/Card.vue'
import MedShipmentDetails from '@/components/medications/MedShipmentDetails.vue'
import MedStatusCard from '@/components/medications/MedStatusCard.vue'
import MedDetailsActionCard from '@/components/medications/MedDetailsActionCard.vue'
import ConfirmDeleteMedicationModal from '@/components/medications/ConfirmDeleteMedicationModal.vue'
import MedLiterature from '@/components/medications/MedLiterature.vue'
import { MedicationStatus, medStatusFinder } from '@/util/medStatusHelpers'

@Component({
  components: {
    Card,
    MedShipmentDetails,
    MedDetailsListItem,
    MedStatusCard,
    MedDetailsActionCard,
    ConfirmDeleteMedicationModal,
    MedLiterature,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError', 'isLoading']),
    ...mapGetters('medications', ['getLiterature']),
  },
})
export default class MedInfoBase extends Vue {
  isInProgress!: (key: string, id?: string) => boolean
  getError!: (key: string, id?: string) => Error
  isLoading!: (key: string, id?: string) => boolean
  getLiterature!: (id: string) => MedicationLiterature

  @Prop({ type: DashboardMedication, required: true })
  medication!: DashboardMedication

  get showLiterature(): boolean {
    // either we have the literature already or we are fetching it for the first time
    // otherwise we don't have it or we haven't started fetching yet
    return !!this.literature || this.literatureLoading
  }

  get literature(): MedicationLiterature | null {
    return this.getLiterature(this.medication.id)
  }

  get literatureLoading(): boolean {
    return (
      this.isLoading('medications/loadLiterature', this.medication.id) ||
      this.isLoading('medications/load', this.medication.id)
    )
  }

  get failedToGetLiterature(): boolean {
    return !!this.getError('medications/loadLiterature')
  }

  get medStatus(): MedicationStatus {
    return medStatusFinder(this.medication)
  }

  get isActive() {
    return this.medStatus === 'active'
  }
}
