import { render, staticRenderFns } from "./MedStatusCard.vue?vue&type=template&id=2b53e7e2&scoped=true&"
import script from "./MedStatusCard.vue?vue&type=script&lang=ts&"
export * from "./MedStatusCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b53e7e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCardHeader } from 'bootstrap-vue'
import { BCardText } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
import IconBottle from '@/assets/icons/icon-bottle.svg'
import IconDaysSupply30 from '@/assets/icons/icon-days-supply-30.svg'
import IconDaysSupply90 from '@/assets/icons/icon-days-supply-90.svg'
import IconDaysSupplyQuantity from '@/assets/icons/icon-days-supply-quantity.svg'
import IconPacketTime from '@/assets/icons/icon-packet-time.svg'
installComponents(component, {BCardHeader,BCardText,BCardTitle,BListGroup,BListGroupItem,BMedia,IconBottle,IconDaysSupply30,IconDaysSupply90,IconDaysSupplyQuantity,IconPacketTime})
