import { render, staticRenderFns } from "./EditPreferencesModal.vue?vue&type=template&id=8fc9baca&scoped=true&"
import script from "./EditPreferencesModal.vue?vue&type=script&lang=ts&"
export * from "./EditPreferencesModal.vue?vue&type=script&lang=ts&"
import style0 from "./EditPreferencesModal.vue?vue&type=style&index=0&id=8fc9baca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fc9baca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
installComponents(component, {BButton,BForm,BFormGroup})
