import { render, staticRenderFns } from "./PrescriptionDetails.vue?vue&type=template&id=0c83ee7b&scoped=true&"
import script from "./PrescriptionDetails.vue?vue&type=script&lang=ts&"
export * from "./PrescriptionDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c83ee7b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BLink } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import IconDaysSupply30 from '@/assets/icons/icon-days-supply-30.svg'
import IconDaysSupply90 from '@/assets/icons/icon-days-supply-90.svg'
import IconDaysSupplyQuantity from '@/assets/icons/icon-days-supply-quantity.svg'
import IconDirections from '@/assets/icons/icon-directions.svg'
import IconPhoneSmall from '@/assets/icons/icon-phone-small.svg'
import IconPlaceMarker from '@/assets/icons/icon-place-marker.svg'
import IconPrescriber from '@/assets/icons/icon-prescriber.svg'
installComponents(component, {BLink,BListGroup,IconDaysSupply30,IconDaysSupply90,IconDaysSupplyQuantity,IconDirections,IconPhoneSmall,IconPlaceMarker,IconPrescriber})
