
























































































import { Component, Vue, Prop } from 'vue-property-decorator'

import Card from '@/components/Card.vue'
import Icon from '@/components/Icon.vue'
import DashboardMedication, { Group, MedicationType } from '@/models/DashboardMedication'
import ArrivalStatus from '@/components/medications/ArrivalStatus.vue'
import AutoshipStatus from '@/components/medications/AutoshipStatus.vue'
import OrderRefillModal from '@/components/medications/OrderRefillModal.vue'
import OrderRefillButton from '@/components/medications/OrderRefillButton.vue'
import CancelRefillModal from '@/components/medications/CancelRefillModal.vue'
import AutoRefillModal from '@/components/medications/AutoRefillModal.vue'
import MedPreferences from '@/components/medications/MedPreferences.vue'
import MedPreferencesInfoModal from '@/components/medications/MedPreferencesInfoModal.vue'
import MedDetailsListItem from '@/components/medications/MedDetailsListItem.vue'

@Component({
  components: {
    Card,
    ArrivalStatus,
    AutoshipStatus,
    AutoRefillModal,
    Icon,
    OrderRefillModal,
    OrderRefillButton,
    CancelRefillModal,
    MedPreferences,
    MedPreferencesInfoModal,
    MedDetailsListItem,
  },
})
export default class MedShipmentDetails extends Vue {
  @Prop({ type: DashboardMedication, required: true })
  medication!: DashboardMedication

  medicationPreferencesEnabled = false

  get canCancelRefill() {
    return this.medication.canCancelRefill || this.medication.canCancelRush
  }

  get showShipmentStatus(): boolean {
    const rx = this.medication.primaryPrescription
    return (
      !!(rx && rx.estimatedArrivalDate) ||
      [Group.Active, Group.Past].includes(this.medication.group)
    )
  }

  get prescription() {
    return this.medication.primaryPrescription
  }

  get showMedicationPreferences() {
    return (
      this.medicationPreferencesEnabled &&
      this.medication.type === MedicationType.UserMedication &&
      this.medication.group === Group.Active &&
      this.medication.activePrescriptions.length > 0
    )
  }

  async created() {
    const selfPrescribed = this.medication.selfPrescribed // B2C-1654 Disable edits for OTCs
    this.medicationPreferencesEnabled = !selfPrescribed
  }
}
