import { render, staticRenderFns } from "./AutoshipStatus.vue?vue&type=template&id=726833dd&scoped=true&"
import script from "./AutoshipStatus.vue?vue&type=script&lang=ts&"
export * from "./AutoshipStatus.vue?vue&type=script&lang=ts&"
import style0 from "./AutoshipStatus.vue?vue&type=style&index=0&id=726833dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "726833dd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
installComponents(component, {BListGroupItem,BMedia})
