








































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Prescription from '@/models/Prescription'

import Icon from '@/components/Icon.vue'
import MedDetailsListItem from '@/components/medications/MedDetailsListItem.vue'

@Component({
  components: {
    Icon,
    MedDetailsListItem,
  },
})
export default class PrescriptionDetails extends Vue {
  @Prop({ type: Prescription, required: true })
  prescription!: Prescription

  @Prop(Boolean)
  hideMedicationPreferences!: boolean

  get physician() {
    return this.prescription?.physician
  }

  get packageText() {
    switch (this.prescription.medicationType) {
      case 'packet':
        return this.$t('In Packets')
      default:
        return this.prescription.medicationType
    }
  }

  get prescriberText() {
    return this.prescription?.selfPrescribed
      ? (this.$t('N/A') as string)
      : this.physician && this.physician.fullName
  }

  get updatePhysicianLink() {
    return {
      path: 'prescriber',
      append: true,
    }
  }

  get updateStatusMessage() {
    const name = this.prescription.updatePhysicianRequest?.name
    return this.prescription.updatePhysicianRequest?.pending
      ? this.$t('updateDoctor.status.pending', { name })
      : this.$t('updateDoctor.status.finished', { name })
  }

  get canUpdatePrescriber() {
    return (
      this.prescription.isDispensable &&
      !this.prescription.selfPrescribed &&
      !this.prescription.updatePhysicianRequest
    )
  }
}
