


































import { mapGetters, mapState } from 'vuex'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { isPast } from 'date-fns'

import { formatDate } from '@/i18n'
import UnreachableCaseError from '@/util/UnreachableCaseError'
import DashboardMedication from '@/models/DashboardMedication'
import User from '@/models/User'
import Icon from '@/components/Icon.vue'
import ChatLink from '@/components/ChatLink.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { ShipmentStatus, shipmentStatusFinder, arrivalDateCopy } from '@/util/medStatusHelpers'

interface ShipmentCopy {
  arrival: string | TranslateResult
  details?: string | TranslateResult
}

@Component({
  components: { Icon, LoadingSpinner, ChatLink },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('asyncStatus', ['isInProgress']),
    ...mapGetters('user', ['hasUser']),
  },
})
export default class ArrivalStatus extends Vue {
  isInProgress!: (key: string) => boolean
  hasUser!: () => boolean
  me?: User

  @Prop({ type: DashboardMedication, required: true })
  medication!: DashboardMedication

  get loadingUser() {
    return !this.hasUser && this.isInProgress('user/loadMe')
  }

  get showDetails() {
    return this.shipmentStatus.details && this.shipmentStatus.details.length
  }

  get shipmentStatus(): ShipmentCopy {
    const deadline = this.medication.changesDeadlineDate
    let arrival: TranslateResult
    let details: TranslateResult | undefined

    switch (this.status) {
      case 'with_arrival_date':
        arrival = arrivalDateCopy(this.medication)
        if (deadline && !isPast(deadline)) {
          details = this.$t('shipmentStatus.estimated.details', {
            deadline: formatDate(deadline, 'MMMM D'),
          })
        }
        break
      case 'waiting':
        arrival = this.$t('shipmentStatus.waiting.arrival')
        details = this.$t('shipmentStatus.waiting.details')
        break
      case 'ready':
        arrival = this.$t('shipmentStatus.ready.arrival')
        details = this.$t('shipmentStatus.ready.details')
        break
      case 'paused':
        arrival = this.$t('shipmentStatus.paused.arrival')
        break
      case 'past':
      case 'by_request':
        arrival = this.$t('shipmentStatus.byRequest.arrival')
        break
      case 'contact_us':
        arrival = this.$t('shipmentStatus.contactUs.arrival')
        break
      case 'loading':
        arrival = this.$t('shipmentStatus.loading.arrival')
        break
      case 'unknown_offcycle':
        arrival = this.$t('shipmentStatus.unknownOffcycle.arrival')
        details = this.$t('shipmentStatus.unknownOffcycle.details')
        break
      case 'unknown':
        arrival = this.$t('shipmentStatus.unknown.arrival')
        details = this.$t('shipmentStatus.unknown.details')
        break
      default:
        throw new UnreachableCaseError(this.status)
    }

    return { details, arrival }
  }

  get noDeliveryScheduled() {
    switch (this.status) {
      case 'waiting':
      case 'paused':
      case 'past':
      case 'by_request':
      case 'contact_us':
        return true
      default:
        return false
    }
  }

  get status(): ShipmentStatus {
    return shipmentStatusFinder(this.medication, this.me)
  }

  get showContactOptions(): boolean {
    return this.status === 'unknown_offcycle'
  }

  created(): void {
    this.$store.dispatch('user/ensureMe')
  }
}
